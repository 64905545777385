<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <DatePicker
          v-model="dateRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="日期范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table stripe :columns="tableColumns" :data="itemList" :loading="isLoading"></Table>
    <Pager :total="itemListCount" :current.sync="page" />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"
import { queryDailyTransaction }from "@/api"

export default {
  name: "AnalysisOrderPage",
  components: { ContentWrapper, Pager },
  data() {
    return {
      topLinks: [{ title: "交易数据", link: this.$route.path }],
      dateRange: ["", ""],
      itemList: [],
      itemListCount: 0,
      page: 1,
      tableColumns: [
        { title: "日期", key: "createdTime" },
        { title: "总订单数", key: "totalOrder" },
        { title: "成交订单数", key: "paidOrder" },
        { title: "已付款项", key: "paidBill" },
        { title: "爆款新品数量", key: "brandNewItemCount" },
        { title: "公益免费数量", key: "freeItemCount" },
        { title: "虚拟物品数量", key: "virtualItemCount" },
        { title: "二手好物数量", key: "usedItemCount" },
        { title: "到店餐饮数量", key: "storeFoodCount" },
      ],
      isLoading: false,
    }
  },
  computed: {},
  watch: {
    dateRange() {
      this.reload()
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        let startTime = null,
          endTime = null
        if (this.dateRange && this.dateRange.length == 2 && this.dateRange[0] instanceof Date) {
          startTime = this.dateRange[0].getTime()
          endTime = this.dateRange[1].getTime()
        }
        const data = await queryDailyTransaction(startTime, endTime)
        this.itemList = data
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
  },
}
</script>
